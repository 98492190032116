<template>
  <div
    class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-30 md:gap-20 xl:gap-30"
  >
    <!-- Display a loading state. -->
    <template v-if="pending">
      <div
        v-for="n in 10"
        class="mobile-only:h-100 md:aspect-[1/2] bg-gray-300 animate-pulse"
        :style="{ animationDelay: n * -100 + 'ms' }"
      ></div>
    </template>
    <template v-else-if="articles?.length">
      <InfoletterTeaser
        v-for="(article, index) in articles.slice(0, 3)"
        :key="index"
        v-bind="article"
      />
    </template>
    <div v-else>
      {{
        $texts(
          'infoletterLoadingError',
          'Infoletter kann aktuell nicht geladen werden',
        )
      }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { InfoletterArticleFragment } from '#graphql-operations'

const { data: articles, pending } = await useLazyAsyncData<
  InfoletterArticleFragment[]
>(() =>
  useCachedGraphqlQuery('infoletterArticles').then(
    (v) => v.data.infoletterArticles || [],
  ),
)
</script>
