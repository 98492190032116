/**
 * Format event dates.
 * If the event spans the same day, display it as "{{ day }}, {{ time }}"; otherwise, display the default format.
 */
interface EventDate {
  formatted: string
  timeOnly: string
  dateOnly: string
}

export function formatEventDate(eventDate: EventDate): string {
  const { formatted, timeOnly, dateOnly } = eventDate
  const date = dateOnly.split(' - ')
  let formattedDate = ''

  if (date.length === 2 && date[0] === date[1]) {
    const day = formatted?.split(' - ')
    formattedDate = day ? day[0] + ', ' + timeOnly : formattedDate
  } else {
    formattedDate = formatted
  }

  return formattedDate
}
