<template>
  <!-- The large version with the image as the background. -->
  <a
    v-if="isLarge"
    :href="href"
    class="box infoletter-teaser relative aspect-[4/5] md:aspect-[1/2] flex flex-col"
    :class="imageStyles?.xl ? 'bg-white' : 'bg-lime'"
    target="_blank"
  >
    <InfoletterStatusIcon v-bind="status" />
    <img
      v-if="imageStyles?.xl"
      :src="imageStyles?.xl"
      class="w-full absolute top-0 h-full left-0 z-10 object-cover"
    />
    <div
      class="box-padding z-20 relative text-white mt-auto bg-gradient-to-t from-gray-900/75 to-gray-100/0"
    >
      <ul v-if="categories" class="flex md:-ml-10 mb-10">
        <li v-for="category in categories">
          <Tag>{{ category }}</Tag>
        </li>
      </ul>

      <h2 class="text-xl lg:text-xl xl:text-4xl hyphens-auto break-words">
        {{ title }}
      </h2>
    </div>
  </a>

  <!-- The default version where the image is half the size of the teaser. -->
  <a
    v-else
    :href="href"
    class="box infoletter-teaser relative flex md:block"
    :class="imageStyles?.l ? 'bg-white' : 'bg-lime'"
    target="_blank"
  >
    <InfoletterStatusIcon v-bind="status" />
    <div class="w-100 shrink-0 md:w-full">
      <img
        v-if="imageStyles?.l"
        :src="imageStyles?.l"
        class="w-full h-full md:h-auto aspect-square object-cover"
      />
    </div>
    <div class="box-padding">
      <ul
        v-if="categories"
        class="flex md:-ml-10 md:mb-20 lg:mb-30 absolute -bottom-10 left-10 md:-mt-[35px] lg:-mt-[45px] md:static"
      >
        <li v-for="category in categories">
          <Tag>{{ category }}</Tag>
        </li>
      </ul>
      <h2
        class="text-sm md:text-lg lg:text-xl xl:text-2xl hyphens-auto break-words"
      >
        {{ title }}
      </h2>
    </div>
  </a>
</template>

<script lang="ts" setup>
import { InfoletterArticleFragment } from '#graphql-operations'

const props = defineProps<{
  title?: string
  lead?: string
  categories?: string[]
  imageStyles?: InfoletterArticleFragment['imageStyles']
  status?: InfoletterArticleFragment['status']
  date?: InfoletterArticleFragment['date']
  url?: InfoletterArticleFragment['url']
}>()

const href = computed(() => props.url?.path || '')
const isLarge = computed(
  () => props.status?.isBreakingNews || props.status?.isSpecialInfo,
)
</script>

<style lang="postcss"></style>
