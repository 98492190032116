<template>
  <NuxtLink :to="url?.path" class="box bg-white md:flex">
    <div class="shrink-0 flex-1 md:w-1/2 md:max-w-[385px]">
      <div class="aspect-square">
        <MediaImage v-if="image" v-bind="image" :image-style="imageStyle" />
      </div>
    </div>
    <div class="flex-1">
      <div class="box-padding flex flex-col h-full">
        <h3 class="text-md md:text-2xl mb-20">{{ title }}</h3>
        <time class="text-base">
          {{ formatEventDate(props.eventDate) }}
        </time>
        <p class="text-base my-30">{{ lead }}</p>
      </div>
    </div>
  </NuxtLink>
</template>

<script lang="ts" setup>
import type { NodeEventTeaserFragment } from '#graphql-operations'
import { formatEventDate } from '~/helpers/formatEventDate'

const props = defineProps<{
  title?: string
  url?: NodeEventTeaserFragment['url']
  lead?: NodeEventTeaserFragment['lead']
  image?: NodeEventTeaserFragment['image']
  eventDate?: NodeEventTeaserFragment['eventDate']
}>()

const imageStyle = defineImageStyle({
  type: 'sizes',
  aspectRatio: 1,
  sizes: {
    sm: 767,
    md: 364,
    lg: 385,
  },
})
</script>
