<template>
  <NuxtLink
    :to="detailUrl"
    class="box bg-white flex flex-row-reverse lg:flex-row text-base group/product items-stretch"
  >
    <div
      class="w-100 h-150 h-[150px] md:w-[240px] md:h-[240px] lg:w-[300px] lg:h-[300px]"
    >
      <MediaImage v-if="image" v-bind="image" :image-style="imageStyle" />
    </div>
    <div
      class="box-padding flex-1 flex flex-col lg:border-l lg:border-l-gray-100 lg:border-r-0"
      :class="{ 'border-r border-r-gray-100': !!image }"
    >
      <div class="lg:flex lg:gap-10 flex-wrap items-center lg:-mt-10 lg:mb-10">
        <div
          v-if="productType && productType.label"
          class="text-small md:text-xs font-medium lg:uppercase"
        >
          {{ productType.label }}
        </div>
        <CommerceTargetGroupIndicator v-bind="targetGroup" />
      </div>
      <h2 class="text-md md:text-xl lg:text-3xl font-semibold leading-none">
        {{ title }}
      </h2>
      <div
        v-if="lead"
        class="mt-10 mb-10 lg:mb-20 line-clamp-2 until-lg:hidden"
        v-html="lead"
      />

      <div
        class="flex gap-10 mt-auto md:-mb-3 lg:-mb-5 justify-between items-end"
        :class="{
          'mobile-only:flex-col mobile-only:items-start mobile-only:mt-20':
            displayButton,
        }"
      >
        <CommerceProductPrice
          v-if="price"
          v-bind="price"
          :roles="paywall?.requiredRoles"
          :role-based-prices="roleBasedPrices"
        />

        <ButtonStyle v-slot="{ classes }" v-bind="targetGroup.buttonProps">
          <div
            class="inline-flex transition translate-y-5 group-hover/product:translate-y-0 whitespace-nowrap"
            :class="[
              {
                'mobile-only:hidden opacity-0 group-hover/product:opacity-100':
                  !displayButton,
              },
              classes,
            ]"
          >
            {{ $texts('showProduct', 'Produkt anzeigen') }}
          </div>
        </ButtonStyle>
      </div>
    </div>
  </NuxtLink>
</template>

<script lang="ts" setup>
import {
  CommerceProductTeaserFragment,
  CommerceProductTeaserTypeFragment,
  FieldTypeRoleBasedPriceFragment,
  PaywallFragment,
} from '#graphql-operations'

const props = defineProps<{
  id?: string
  title?: string
  variation?: CommerceProductTeaserFragment['variation']
  url?: CommerceProductTeaserFragment['url']
  lead?: string
  paywall?: PaywallFragment
  productType?: CommerceProductTeaserTypeFragment
  displayButton?: boolean
}>()

const detailUrl = computed(() => {
  if (props.variation?.url?.path) {
    return props.variation.url.path
  }

  return props.url?.path
})

const price = computed(() => {
  return props.variation?.price
})

const roleBasedPrices = computed<FieldTypeRoleBasedPriceFragment[]>(
  () => props.variation?.roleBasedPrice || [],
)

const image = computed(() => {
  return props.variation?.image
})

const targetGroup = computed(() => {
  return useCommerceTargetGroup(props.paywall?.requiredRoles)
})

const imageStyle = defineImageStyle({
  type: 'pictures',
  pictures: {
    sm: {
      width: 100,
      height: 150,
    },
    md: {
      width: 240,
      height: 240,
    },
    lg: {
      width: 300,
      height: 300,
    },
  },
})
</script>
