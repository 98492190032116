<template>
  <div class="flex gap-10 absolute top-0 left-0 p-10 md:p-15 z-30">
    <div
      v-for="icon in icons"
      class="w-30 h-30 md:w-50 md:h-50 bg-white text-gray-900 rounded flex items-center justify-center"
    >
      <SpriteSymbol :name="icon" class="w-15 h-15 md:w-30 md:h-30" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useDrupalUser } from '~/stores/drupalUser'

const drupalUser = useDrupalUser()

const props = defineProps<{
  isBreakingNews?: boolean
  isSpecialInfo?: boolean
  isNonPublic?: boolean
}>()

const icons = computed(() => {
  const icons: string[] = []

  if (props.isBreakingNews) {
    icons.push('breaking-news')
  }

  if (props.isNonPublic && drupalUser.isLoggedIn) {
    icons.push('unlocked')
  }

  if (props.isSpecialInfo) {
    icons.push('special-info')
  }

  return icons
})
</script>
