<template>
  <div v-if="activeEvent" class="grid grid-cols-1 lg:grid-cols-3 gap-30">
    <div class="relative lg:col-span-2">
      <EventsHomeTeaser v-bind="activeEvent" />

      <div
        v-if="promoted.length > 1"
        class="absolute -bottom-25 left-1/2 box-padding pb-0 hidden md:block"
      >
        <div class="buttons">
          <button
            class="button is-primary is-circle"
            :disabled="active === 0"
            @click="active--"
          >
            <SpriteSymbol name="arrow" class="transform rotate-180 h-10" />
          </button>
          <button
            class="button is-primary is-circle"
            :disabled="active === promoted.length - 1"
            @click="active++"
          >
            <SpriteSymbol name="arrow" class="h-10" />
          </button>
        </div>
      </div>
    </div>
    <div class="box relative box-padding bg-white">
      <TitleWithIcon
        :title="$texts('currentEvents', 'Aktuelle Events')"
        hint-color="text-green"
      >
        <SpriteSymbol
          name="calendar"
          class="text-4xl md:text-5xl is-text-sized mb-10 md:mb-30 text-green"
        />
      </TitleWithIcon>

      <div class="mt-20 lg:mt-30 flex flex-col gap-10">
        <NuxtLink
          v-for="(event, i) in nextEvents"
          :key="i"
          :to="event.url?.path"
          class="link inline-flex text-sm leading-tight"
        >
          <SpriteSymbol name="arrow" class="h-20 w-20 mr-10 flex-shrink-0" />
          {{ event.title }}
        </NuxtLink>
      </div>
    </div>
  </div>
  <div class="text-center mt-70">
    <NuxtLink
      :to="{ name: 'pharmasuisse-events-overview' }"
      class="button is-primary"
      >{{ $texts('showAllEvents', 'Alle Events anzeigen') }}</NuxtLink
    >
  </div>
</template>

<script lang="ts" setup>
import {
  HomeEventNextFragment,
  NodeEventTeaserFragment,
} from '#graphql-operations'
import { falsy } from '~/helpers/type'

const active = ref(0)

const { data } = await useLazyAsyncData(() =>
  useCachedGraphqlQuery('pharmasuisseHomeEvents').then((v) => v.data),
)

const promoted = computed<NodeEventTeaserFragment[]>(() =>
  (data.value?.promoted?.events || []).filter(falsy),
)

const nextEvents = computed<HomeEventNextFragment[]>(() =>
  (data.value?.nextEvents?.events || []).filter(falsy),
)

const activeEvent = computed<NodeEventTeaserFragment | undefined>(
  () => promoted.value?.[active.value],
)
</script>
